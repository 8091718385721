const initialState = {
  list: [],
  customers: [],
  signupList: [],
  liveStream: {
    videoData: null,
    liveImage: null,
    liveExtension: '.png',
    liveImageSource: null,
    liveInfoImage: null,
    liveInfoExtension: '.png',
    liveInfoImageSource: null,
    productList: [],
    fakeTicket: [],
    bannedKeywords: [],
    videoDuration: null,
  },
  form: null,
}

export default function liveStreamReducer(state = initialState, action) {
  switch (action.type) {
    case 'LIVE_STREAM_SET_LIST': {
      state.list = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_VIDEO_DATA': {
      state.liveStream.videoData = action.payload
      state.liveStream.videoDuration = action.videoDuration
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_IMAGE': {
      state.liveStream.liveImage = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_EXTENSION': {
      state.liveStream.liveExtension = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_IMAGE_SOURCE': {
      state.liveStream.liveImageSource = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_INFO_IMAGE': {
      state.liveStream.liveInfoImage = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_INFO_EXTENSION': {
      state.liveStream.liveInfoExtension = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_LIVE_INFO_IMAGE_SOURCE': {
      state.liveStream.liveInfoImageSource = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_ADD_PRODUCT_LIST': {
      const products = state.liveStream.productList
      state.liveStream.productList = [...products, action.payload]
      return { ...state }
    }

    case 'LIVE_STREAM_EDIT_PRODUCT_LIST': {
      state.liveStream.productList = [...action.payload]
      return { ...state }
    }

    case 'LIVE_STREAM_DELETE_PRODUCT_LIST': {
      const products = state.liveStream.productList
      products.splice(action.payload, 1)
      state.liveStream.productList = products
      return { ...state }
    }

    case 'LIVE_STREAM_SET_FAKE_TICKET': {
      const fakeTickets = state.liveStream.fakeTicket
      state.liveStream.fakeTicket = [...fakeTickets, action.payload]
      return { ...state }
    }

    case 'LIVE_STREAM_EDIT_FAKE_TICKET': {
      state.liveStream.fakeTicket = [...action.payload]
      return { ...state }
    }

    case 'LIVE_STREAM_DELETE_FAKE_TICKET': {
      const fakeTickets = state.liveStream.fakeTicket
      fakeTickets.splice(action.payload, 1)
      state.liveStream.fakeTicket = fakeTickets
      return { ...state }
    }

    case 'LIVE_STREAM_SET_BANNED_KEYWORDS': {
      state.liveStream.bannedKeywords = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_RESET': {
      state = { ...initialState }
      state.liveStream.productList = []
      state.liveStream.fakeTicket = []
      state.liveStream.bannedKeywords = []
      return { ...state }
    }

    case 'LIVE_STREAM_LOAD': {
      state.liveStream = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_LOAD_FORM': {
      state.form = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_LOAD_CUSTOMERS': {
      state.customers = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_SET_SIGNUP_LIST': {
      state.signupList = action.payload
      return { ...state }
    }

    case 'LIVE_STREAM_RELOAD': {
      state = initialState
      return { ...state }
    }

    default:
      return state
  }
}
